$(document).ready(function () {
    $("#document").mask("999.999.999-99");
    $("#nrdocument").mask("99.999.999/9999-99");
    // $("#dtnascimento").mask("99/99/9999");
    $("#phone").mask("(99) 99999-9999");
    $(".date").mask("99/99/9999");
    $('.money').mask("#.##0,00", {reverse: true});

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });


    $('#myTableItens').DataTable({
        pagingType: 'full_numbers',
        columnDefs: [
            { width: '50%', targets: 1 },
            { width: '5%', targets: 0 }
        ],
        language: {
            url: '//cdn.datatables.net/plug-ins/1.13.6/i18n/pt-BR.json',
        }
    });

});

dateIn = function()
{
    var data_ida = document.getElementById('dt_ida').value
    document.getElementById("dt_retorno").min = data_ida;
    var data_volta = document.getElementById("dt_retorno").value

    if(data_volta < data_ida)
    {
        document.getElementById("dt_retorno").value = ''
    }
}


limpa_formulário_cep = function() {
	document.getElementById('rua').value=("");
	document.getElementById('bairro').value=("");
	document.getElementById('cidade').value=("");
	document.getElementById('uf').value=("");
}

meu_callback = function(conteudo) {
	if (!("erro" in conteudo)) {
        document.getElementById('rua').value=(conteudo.logradouro);
        document.getElementById('bairro').value=(conteudo.bairro);
        document.getElementById('cidade').value=(conteudo.localidade);
        document.getElementById('uf').value=(conteudo.uf);
    } else {
        //CEP não Encontrado.
        limpa_formulário_cep();
        alert("CEP não encontrado.");
    }
}

pesquisacep = function(valor) {

	document.getElementById("nradress").focus();

	var cep = valor.replace(/\D/g, '');
	if (cep != "") {
          var validacep = /^[0-9]{8}$/;
          if(validacep.test(cep)) {
			document.getElementById('rua').value="...";
			document.getElementById('bairro').value="...";
			document.getElementById('cidade').value="...";
			document.getElementById('uf').value="...";

            var script = document.createElement('script');
            script.src = 'https://viacep.com.br/ws/'+ cep + '/json/?callback=meu_callback';
            document.body.appendChild(script);

          } else {
              limpa_formulário_cep();
              alert("Formato de CEP inválido.");
        }
      } else {
        limpa_formulário_cep();
    }
};

myFunctionSearch = function(id_client, name, documento, phone, email){
    document.getElementById("id_client").value = id_client;
    document.getElementById("name_client").value = name;
    document.getElementById("document").value = documento;
    document.getElementById("phone").value = phone;
    document.getElementById("email").value = email;
}

paymentCard = function()
{
   var method_pay = document.getElementById('method_pay').value
    if(method_pay == 3){
        $('#cardParcel').show(500)
    }else{
        $('#cardParcel').hide()
    }

}

var i = 1;

itensAddLine = function(){
    i++;
    $('#field_dynamic').append('<div class="row" id="row'+i+'"><hr style="margin:12px 0 0;"><div class="col-12 col-md-4"><label for="name_passageiro" class="form-label">Passageiro:</label><input type="text" name="name_passageiro[]" id="name_passageiro'+i+'" placeholder="Passageiro:" class="form-control" required></div><div class="col-12 col-md-3"><label for="document_passageiro" class="form-label">CPF:</label><input type="text" name="document_passageiro[]" id="document_passageiro'+i+'" placeholder="CPF:" class="form-control" required></div><div class="col-12 col-md-3"><label for="nascimento_passageiro" class="form-label">Data Nascimento:</label><input type="date" name="nascimento_passageiro[]" id="nascimento_passageiro'+i+'" placeholder="Data Nascimento:" class="form-control" required></div><div class="col-12 col-md-2"><a class="btn btn-success" onclick="itensAddLine()" style="position: relative; top: 50%;"><i class="bx bx-user-plus"></i></a><a class="btn btn-danger"style="position: relative; top: 50%;" onclick="itensRemoveLine('+i+')"><i class="bx bxs-trash"></i></a></div><span><strong>Dados do Passaporte</strong>(se houver)</span><br><div class="col-12 col-md-4"><label for="passaporte" class="form-label">Número do Passaporte:</label><input type="text" name="passaporte[]" id="passaporte'+i+'" placeholder="Número do Passaporte:" class="form-control"></div><div class="col-12 col-md-3"><label for="nascimento_passageiro" class="form-label">País de Emissão:</label><input type="text" name="pais_emissao[]" id="pais_emissao'+i+'" placeholder="País de Emissão:" class="form-control"></div><div class="col-12 col-md-3"><label for="validade" class="form-label">Validade:</label><input type="date" name="validade[]" id="validade'+i+'" placeholder="Validade:" class="form-control"></div></div>');
}

itensRemoveLine = function(button_id){
    $("#row"+button_id).remove();
}

